import { createStore } from "vuex";
import { auth } from "./auth.module";
import { reset } from "./reset.module";
import { saveToLocalStorage, loadFromLocalStorage } from "../assets/js/crypto-utils.js";
import router from "../router/index.js";
import { get } from "jquery";

function getCartItems() {
  let cartItems = loadFromLocalStorage('cartItems') || [];
  let userData = loadFromLocalStorage('userData') || null;
  let newUserId = userData ? userData.id : null;
  if (cartItems) {
    cartItems = cartItems.filter((i) => i.userId === newUserId);
    return cartItems;
  }
  return [];
}

export default createStore({
  state: {
    hideConfigButton: false,
    isPinned: true,
    showConfig: false,
    isTransparent: "bg-full",
    color: "primary",
    idDisciplina: null,
    newNotifications: [],
    permissoesUser: [],
    cartItems: getCartItems(),
    isNavFixed: false,
    isAbsolute: false,
    showNavs: true,
    showSidenav: true,
    showNavbar: true,
    showFooter: true,
    showMain: true,
    titlePage: null,
    breadcrumbItems: [],
    navbarFixed:
      "position-sticky blur shadow-blur left-auto top-1 z-index-sticky px-0 mx-4",
    absolute: "position-absolute px-4 mx-0 w-100 z-index-2"
  },
  modules: {
    auth,
    reset
  },
  mutations: {
    toggleConfigurator(state, value) {
      if (typeof value === 'boolean') {
        state.showConfig = value;
      } else {
        state.showConfig = !state.showConfig;
      }
    },
    addCartItem(state, item) {
      let userData = loadFromLocalStorage('userData') || null;
      if (!userData || !userData.id) {
        return;
      }
      let newUserId = userData.id;
      if (state.cartItems.find((i) => i.id === item.id)) return;
      const wasEmpty = state.cartItems.length === 0;
      const itemWithUser = { ...item, userId: newUserId };
      state.cartItems.push(itemWithUser);
      saveToLocalStorage('cartItems', state.cartItems);
      if (wasEmpty) {
        this.commit('toggleHideConfig', false);
        this.commit('toggleConfigurator', true);
      }
    },
    removeCartItem(state, item) {
      state.cartItems = state.cartItems.filter((i) => i.id !== item.id);
      saveToLocalStorage('cartItems', state.cartItems);
      this.commit('checkCartItem');
    },
    checkCartItem(state) {
      if (state.cartItems.length === 0) {
        this.commit('toggleHideConfig', true);
        this.commit('toggleConfigurator', false);
      } else {
        this.commit('toggleHideConfig', false);
      }
    },
    navbarMinimize(state) {
      const sidenav_show = document.querySelector(".g-sidenav-show");
      if (sidenav_show.classList.contains("g-sidenav-hidden")) {
        sidenav_show.classList.remove("g-sidenav-hidden");
        sidenav_show.classList.add("g-sidenav-pinned");
        state.isPinned = true;
      } else {
        sidenav_show.classList.add("g-sidenav-hidden");
        sidenav_show.classList.remove("g-sidenav-pinned");
        state.isPinned = false;
      }
    },
    sidebarType(state, payload) {
      state.isTransparent = payload;
    },
    cardBackground(state, payload) {
      state.color = payload;
    },
    navbarFixed(state) {
      state.isNavFixed = !state.isNavFixed;
    },
    toggleEveryDisplay(state) {
      state.showNavbar = !state.showNavbar;
      state.showSidenav = !state.showSidenav;
      state.showFooter = !state.showFooter;
    },
    toggleHideConfig(state, status) {
      state.hideConfigButton = status;
    },
    atualizarCorDinamica(state, novaCor) {
      state.corDinamica = novaCor;
    },
    atualizarNewNotifications(state, notifications) {
      state.newNotifications = notifications;
    },
    atualizarPermissoes(state, permissoes) {
      state.permissoesUser = permissoes;
    },
    setIdDisciplina(state, idDisciplina) {
      state.idDisciplina = idDisciplina;
    },
    setBreadcrumbItems(state, items) {
      state.breadcrumbItems = items;
    },
    setTitlePage(state, title) {
      state.titlePage = title;
    },
    updateCartItems(state) {
      state.cartItems = getCartItems();
    }
  },
  actions: {
    toggleSidebarColor({ commit }, payload) {
      commit("sidebarType", payload);
    },
    setCardBackground({ commit }, payload) {
      commit("cardBackground", payload);
    },
    atualizarCorDinamica({ commit }, novaCor) {
      commit("atualizarCorDinamica", novaCor);
    },
    atualizarNewNotifications({ commit }, notifications) {
      commit('atualizarNewNotifications', notifications);
    },
    atualizarPermissoes({ commit }, permissoes) {
      commit('atualizarPermissoes', permissoes);
    },
    getRouteByName({ commit }, routeName) {
      const route = router.getRoutes().find(route => route.name === routeName);
      return route;
    },
    async fetchParentRoutes({ dispatch }, route) {
      const parents = [];
      let currentRoute = route;
      while (currentRoute.meta && currentRoute.meta.parent) {
        const parent = await dispatch('getRouteByName', currentRoute.meta.parent);
        if (parent) {
          parents.unshift(parent);
          currentRoute = parent;
        } else {
          break;
        }
      }
      return parents;
    },
    async updateBreadcrumb({ commit, dispatch }, route) {
      const items = [];
      const parentRoutes = await dispatch('fetchParentRoutes', route);
  
      for (const parentRoute of parentRoutes) {
        items.push({
          label: parentRoute.meta.breadcrumb || parentRoute.name,
          route: {
            name: parentRoute.name,
            params: route.params
          }
        });
      }
      for (const r of route.matched) {
        if (r.name) {
          items.push({
            label: r.meta.breadcrumb || r.name,
            route: {
              name: r.name,
              params: route.params
            },
            active: true
          });
        }
      }
      commit('setBreadcrumbItems', items);
    },
    updateCartItemsAction({ commit }) {
      commit('updateCartItems');
    }
  },
  getters: {
  },
});
