import authService from "../services/auth.service";
import { saveToLocalStorage, loadFromLocalStorage } from "../assets/js/crypto-utils.js";

const user = JSON.parse(localStorage.getItem("token"));
const initialState = user ? { loggedIn: true, userRoutePermissions: [] } : { loggedIn: false, userRoutePermissions: [] };

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    async login({ commit }, userData) {
      await authService.login(userData);
      commit("logMeIn");
    },

    async logout({ commit }) {
      try {
        await authService.logout();
      } finally {
        commit("logMeOut");
      }
    },

    async register({ commit }, userData) {
      try {
        const response = await authService.register(userData);
        return response;
      } catch (error) {
        return error.response.data;
      }
    },
    async fetchUserRoutePermissionsFromBackend({ commit }) {
      try {
        const userData = loadFromLocalStorage('userData');
        const response = await Axios.useAxios('get', '/user/route-permissions/'+userData.id);
        commit('setUserRoutePermissions', response.data.routes);
        return response.data.routes;
      } catch (error) {
        throw error;
      }
    },
    async checkRoutePermission({ state }, routeName) {
      if (!state.userRoutePermissions.length) {
        await this.dispatch('auth/fetchUserRoutePermissionsFromBackend');
      }
      return state.userRoutePermissions.includes(routeName);
    }
  },
  mutations: {
    logMeIn(state) {
      state.loggedIn = true;
    },
    logMeOut(state) {
      state.loggedIn = false;
    },
    setUserRoutePermissions(state, permissions) {
      state.userRoutePermissions = permissions;
    },
  },

  getters: {
    loggedIn(state) {
      return state.loggedIn;
    },
  },
};
