import axios from "axios";
import authHeader from "./auth-header";
import { loadFromLocalStorage } from "@/assets/js/crypto-utils.js";
const API_URL = process.env.VUE_APP_API_BASE_URL + '/';

export default {
  async login(user) {
    const response = await axios.post(API_URL + "login", user);
    if (response.data.access_token) {
        localStorage.setItem("userData", response.data.userData);
        localStorage.setItem("system", response.data.system);
        localStorage.setItem("token", JSON.stringify(response.data.access_token));
        
    }
  },

  async logout() {
    try {
      const userData = loadFromLocalStorage('userData');
      const user = userData ? userData.user : null;
      try{
        await axios.post(API_URL + "logout", {user: user}, { headers: authHeader() });
      } catch{
        
      }
      
    } finally {
      try{
        localStorage.removeItem("token");
        localStorage.removeItem("userData");
      } catch{ 
      } 
    }
  },

  async register(user) {
    try {
      const response = await axios.post(API_URL + "register", user);
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
};
