<template>
  <div v-if="loading" class="loading-overlay">
    <div class="loading-spinner"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    show() {
      this.loading = true;
    },
    hide() {
      this.loading = false;
    },
  },
};
</script>


<style scoped>
.loading-overlay {
  position: absolute; /* Mudança para absolute */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 50vh;
  background-color: rgb(47 49 54);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Assegure que o overlay fique acima do conteúdo */
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 5px solid rgb(250, 138, 9);
  border-top-color: white;
  border-radius: 50%;
  animation: spin 1s infinite linear;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>